.Draftail-Editor {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: transparent;
	z-index: 1;
	place-content: center;
	text-align: center;
	align-items: center;
	opacity: 1;
}
.Draftail-Toolbar {
	padding: 0.25rem;
	margin: 0.25rem;
	background-color: transparent;
	color: #333;
}

.DraftEditor-editorContainer {
	position: relative;
	width: 90vw;
	height: 90vw;
	margin-bottom: 10vw;
	display: table;
	border: 0px solid transparent;
	overflow: hidden;
	opacity: 1;
}

.Draftail-Editor--readonly .DraftEditor-editorContainer {
	opacity: 1;
}

.public-DraftStyleDefault-block {
	margin: 0;
	padding: 0;
}
.Draftail-block--unstyled {
	margin: 0.5vw;
}

.square-element {
	padding-top: 2vh;
	height: 14vw;
}

.img-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
