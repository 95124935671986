.Explore-Analytics-Header {
	margin-right: auto;
}

.Explore-Analytics-Buttons {
	margin-left: auto;
	align-items: center;
}

.Explore-Analytics-Grid {
	height: 39rem;
	min-width: 100%;
}

.Explore-Analytics-Children {
	margin-left: auto;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
	font-weight: 700 !important;
}

.MuiDataGrid-root .MuiDataGrid-iconSeparator {
	visibility: hidden !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
	text-align: center !important;
}

.MuiDataGrid-root .MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount {
	visibility: hidden !important;
}

.ToolTip-Cell-Renderer {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.MuiDataGrid-columnHeaderTitleContainer {
	padding-left: 0 !important;
}

.explore-analytics-search-input {
  margin-right: 20px !important;
}
