/* Your styles goes here. */
@font-face {
	font-family: "cursiv";
	src: local("CURSIV"), url(../assets/fonts/CURSIV.TTF) format("truetype");
}

@font-face {
	font-family: Ciutadella;
	font-weight: normal;
	src: url(../assets/fonts/Ciutadella-Regular.otf) format("opentype");
}

@font-face {
	font-family: Ciutadella;
	font-weight: bold;
	src: url(../assets/fonts/Ciutadella-Bold.otf) format("opentype");
}

@font-face {
	font-family: "Arial";
	font-style: normal;
	font-weight: 400;
	src: local("Arial"), url(../assets/fonts/ARIAL.woff) format("woff");
}

@font-face {
	font-family: "Arial Black";
	font-style: italic;
	font-weight: 400;
	src: local("Arial Black"), url(../assets/fonts/ARIALBLACKITALIC.woff) format("woff");
}

@media screen and (max-device-width: 480px) {
	body {
		-webkit-text-size-adjust: none;
	}
}

.credential-description p {
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: normal;
}

.credential-description ol,
.credential-description ul {
	margin: 0;
}

.new-verifier .credential-description h1 span,
.new-verifier .credential-description strong,
.new-verifier .credential-description p em {
	color: initial !important;
}

.new-verifier .jss6 {
	font-size: 1rem;
}

.print-source {
	display: none;
}

.wrap-body {
	flex-direction: row;
	display: flex;
	overflow: hidden;
	border: "1px solid transparent";
}

.certificateLogo {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1280px;
	margin: 0px auto;
	padding: 7px 0;
}

@media screen and (max-width: 560px) {
	.certificateLogo {
		display: block;
		text-align: center;
	}
}

.imgGrayScale {
	filter: grayscale(100%);
}

@media print {
	.wrap-body {
		box-sizing: border-box;
	}

	.print-source {
		display: inline;
		align-self: flex-end;
	}
	@page {
		size: A4 landscape;
		margin: 15mm 15mm 15mm 15mm;
	}
}

#root {
	min-height: 100vh;
}

.expiryDate input[type="date"],
.expiryDate input[type="date"]:focus {
	text-transform: uppercase;
}

.no-suggestions {
	padding: 0.5rem;
	position: absolute;
	z-index: 9;
	width: 100%;
	background: #fff;
}
.suggestions {
	border-top-width: 0;
	list-style: none;
	margin-top: 0;
	max-height: 247px;
	overflow-y: auto;
	padding-left: 0;
	width: 100%;
	margin-block-end: 0;
}
.suggestions li {
	padding: 0.5rem;
	margin-bottom: 1px;
}
.suggestion-active,
.suggestions li:hover {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.08);
}
/* .suggestions li:not(:last-of-type) {
	border-bottom: 1px solid #999;
  } */

.suggestion-input {
	padding: 18px 14px;
	line-height: 1.1876em;
	font-size: 1rem;
	box-sizing: border-box;
	align-items: center;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	border: 1px solid rgba(0, 0, 0, 0.23);
	background: none;
	border-radius: 4px;
	width: 100%;
}

.suggestion-wrapper {
	position: absolute;
	width: 100%;
	z-index: 9;
	background: #fff;
}

.search-wrapper {
	width: 100%;
	position: relative;
}

.suggestion-input-wrap {
	width: 100%;
	position: relative;
}

.suggestion-input-wrap .error {
	color: #f44336;
	margin-top: 2px;
	position: absolute;
	font-size: 0.9rem;
}

.suggestion-input.input-error,
.suggestion-input.input-error:focus {
	border-color: #f44336;
}

.reset-suggestion {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	background: no-repeat;
	border: none;
	cursor: pointer;
	color: currentColor;
	line-height: 0;
	padding: 5px;
}

.reset-suggestion:hover {
	background-color: rgba(0, 0, 0, 0.04);
}

.btn-wrap {
	display: flex;
	justify-content: space-between;
	margin: 4px;
}

.btn-wrap span {
	color: #fff;
	background-color: #324353;
	padding: 6px 10px;
	font-size: 0.85rem;
	font-family: Roboto, sans-serif;
	line-height: 17px;
	border-radius: 4px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	border: none;
	cursor: pointer;
}

.btn-wrap span:hover {
	background-color: #232e3a;
}

.suggestion-input:focus-visible {
	border-color: rgba(0, 0, 0, 0.87);
	outline: none;
	/* border-radius: 4px; */
}

.hide-btn {
	display: none;
}

.search-wrapper {
	margin-bottom: 3px;
}

.org-db-connection .suggestion-input-wrap p.error {
	position: initial;
}

.darkMode .suggestion-input {
	color: #fff;
	border-color: rgba(255, 255, 255, 0.23);
}

.darkMode .suggestion-input:focus-visible {
	border-color: rgba(255, 255, 255, 1);
}

.darkMode .suggestion-wrapper {
	background: #324353;
}

.darkMode .btn-wrap span {
	background-color: #fafafa;
	color: #000;
}

.darkMode .reset-suggestion:hover {
	background-color: rgba(255, 255, 255, 0.08);
}

.darkMode .no-suggestions {
	background: #324353;
}

.MuiTypography-colorSecondary {
	color: #9c49ed !important;
}
