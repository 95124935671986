.nft-catalog-icon {
  width: 23.5px;
  height: 23.5px;
}
.nft-main-container {
  display: flex;
  flex-direction: column;
}

.nft-mint-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.nft-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.nft-card-box {
  border: 2px solid #9c49ed;
}

.nft-card {
  width: 18rem;
  height: max-content;
}

.nft-card-header {
  font-size: 1.3rem;
  padding: 0.6rem;
}

.nft-card-image-container {
  width: 100%;
  height: 18rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nft-card-image {
  width: 100%;
  /* height: 100%; */
}

.nft-card-box {
  height: 70%;
}

.nft-card-footer {
  margin-top: 0.5rem;
}

.nft-card-footer-btn {
  width: 100%;
  background-color: #9c49ed;
  color: white;
  cursor: pointer;
  border-radius: 0.4rem;
  height: 2.1rem;
  font-weight: bold;
  font-size: 1rem;
  border: none;
}

.nft-card-footer-btn-failed {
  width: 100%;
  background-color: #e74747;
  color: white;
  cursor: pointer;
  border-radius: 0.4rem;
  height: 2.1rem;
  font-weight: bold;
  font-size: 1rem;
  border: none;
}

.nft-card-footer-btn-progress-container {
  width: 100%;
  margin: auto;
}

.progress-bar {
  height: 2.1rem;
  border-radius: 0.4rem;
  background-color: #d3b8ea;
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #9c49ed;
  border-radius: 0.4rem;
  animation: indeterminateAnimation 1.5s infinite linear;
  transform-origin: 0 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
